export default function inputHandler() {
    $ = jQuery;

    $('input').on('input', function (event) {
        const input = $(this);

        if (input.val() !== '') {
            input.addClass('filled');
        } else {
            input.removeClass('filled');
        }
    });

    $('input').on('keydown', function (event) {
        const input = $(this);
        const type = input.attr('type');
        const { key } = event;
        
        if (type === 'number') {
            if (["e", "E", "+", "-"].includes(key) && event.preventDefault());
        }
    });

    $('textarea').on('input', function () {
        const input = $(this);

        if (input.val() !== '') {
            input.addClass('filled');
        } else {
            input.removeClass('filled');
        }
    });

    $('select[name="temat"]').on('select2:select', function() {
        const selVal = $(this).val();
        const requiredText = document.querySelector('.js-cf-form-required-text');

        if (!requiredText) return;
        if (selVal === 'Faktury i płatności') {
            requiredText.style.display = 'none';
        } else {
            requiredText.style.display = 'block';
        }
    })
}
